body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grecaptcha-badge {
  opacity: 0;
  position: absolute;
  z-index: -9999;
}

.css-yk16xz-control {
  font-family: 'Gilroy', 'Roboto', Helvetica, Arial, sans-serif !important;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0px !important;
}
